define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "account": {
      "accountFor": "{name} Account",
      "addUser": "Add a collaborator",
      "availableToAdministrators": "This page is only available to account administrators.",
      "availableToOwners": "This page is only available to the account owner.",
      "availableToSuperAdministrators": "This page is only available to super administrators.",
      "notPubliclyAvailable": "Downloads are not publicly available",
      "resendInvite": "Resend Invite",
      "sendInvite": "Send Invite",
      "type": {
        "admin": "Admin",
        "collaborator": "Collaborator",
        "pending": "Pending"
      }
    },
    "actions": {
      "clipFilm": "Clip Film",
      "getStarted": "Get started",
      "login": "Login to Stattracker",
      "startTracking": "Start tracking"
    },
    "app": {
      "account": "Account Settings",
      "addGame": "Add Game",
      "addNewSeasonFor": "Add New Season for ",
      "addRoster": "Add Roster",
      "addSeason": "Add Season",
      "addTeam": "Add Club",
      "addVideo": "Add Video",
      "advancedOptions": "Advanced Options",
      "analyzeBlurb": "In depth statistic reporting, and data visualization provide the analysis tools to make your team better.",
      "analyzeHeader": "",
      "breakdownBlurb": ", or have our dedicated statistician's break your game film down on demand.  You upload film, we give you stats.  Costs are dependent on the desired detail level, and required turnaround time.",
      "closeAccount": "Close Account",
      "confirmEmailChange": "Confirm Email Change",
      "confirmPasswordChange": "Confirm Password Change",
      "dashboard": "Dashboard",
      "downgrade": "Downgrade",
      "emailChanged": "Email Changed",
      "followBlurb": "Don't miss a play with real-time updates beamed from the sideline directly to your device.",
      "followHeader": "Follow your team's games in real-time",
      "freeVersion": "Free Version",
      "gameAdd": "Schedule Game",
      "gameFilmBlurb": "Watch your team's best plays and determine points of improvement on any device.",
      "gameFilmHeader": "Review game film from anywhere",
      "gameScheduled": "This game is scheduled for ",
      "goToTeam": "Go to my team",
      "goToTeamHelp": "Enter your team's identifier below. If you aren't sure what that is, contact your team's admin/coordinator or Hachisoft Support.",
      "highlightPlayer": "Highlight Player",
      "inThe": " in the ",
      "invalidEmail": "The email provided is not valid",
      "live": "Live Games",
      "login": "Login",
      "logout": "Logout",
      "makeAvailable": "Make available in season",
      "maxUsageInPeriod": "*maximum usage in month",
      "meteredVideo": "Video Usage Subscription",
      "multiDeviceAlt": "real-time multi-device tracking",
      "noPublicPages": "You don't have any public pages!",
      "noReportData": "Finish statting to see your visuals",
      "openingBlurb": "Intuitive <em>digital</em> stats you can capture, analyze, and share at the tap of a button",
      "playByPlayBlurb": "Play by play real time stats directly from your device, so your friends and family don't miss a play.",
      "premiumFeature": "This is a premium feature, and is not available on the 'Free' plan.  Please consider helping us build great apps by upgrading your account today!",
      "pricePerGB": "Monthly price per gigabyte",
      "pricing": "Pricing",
      "privacy": "Privacy Policy",
      "refundBlurb": "Hachisoft stands behind its products and services.  If you have an issue please contact support@hachisoft.com, and we will make every attempt to make things right.<br>Refunds may be prorated, and may include processor fees.",
      "remember": "Remember my choice",
      "report": {
        "buildHTML": "Build HTML",
        "buildMaxPreps": "Build Max Preps",
        "buildPDF": "Build PDF",
        "buildXLSX": "Build XLSX",
        "customFilter": "Custom",
        "filtered": "Filtered Report",
        "gameType": "Game Type",
        "leagueFilter": "League",
        "neutralFilter": "Neutral",
        "playoffFilter": "Playoff",
        "settings": "Report Settings"
      },
      "reporting": "Reporting",
      "resetGameData": "Reset Game Data",
      "resetPassword": "Reset Password",
      "rosterForNewSeason": "Roster for New Season",
      "shareBlurb": "Share stats, film breakdowns, shot charts, and reports with your team, league, or community so everyone is in the loop.  Control who sees what.",
      "signUp": "Sign Up",
      "submitForBreakdown": "Submit for breakdown",
      "support": "Support",
      "terms": "Terms and Conditions",
      "trackHeader": "Track Stats in Real Time",
      "upgrade": "Upgrade",
      "useGravatar": "Use Gravatar",
      "usePrepaid": "Use Prepaid",
      "videoBlurb": "Upload as much game film as you want.  Our subscription model only charges you for the storage you use.",
      "watchLive": "Watch live"
    },
    "augment": {
      "description": "Basketball Stattracker needs more information to finalize your account"
    },
    "common": {
      "activePlayers": "Active Players",
      "allSeasons": "All Seasons",
      "allow": "Allow",
      "analyze": "Analyze",
      "api": "API",
      "augment": "Additional Information",
      "available": "Available",
      "backup": "Backup",
      "bench": "Bench",
      "bonus": "Bonus",
      "boxscore": "Box Score",
      "cancel": "Cancel",
      "change": "Change",
      "checkout": "Checkout",
      "clear": "Clear",
      "close": "Close",
      "completed": "Completed",
      "confirm": "Confirm",
      "created": "Created",
      "date": "Date",
      "day": "Day",
      "delete": "Delete",
      "deleteall": "Delete All",
      "demote": "Demote",
      "description": "Description",
      "dest": "Destination",
      "doubleBonus": "Double Bonus",
      "downloads": "Downloads",
      "due": "Due",
      "edit": "Edit",
      "email": "Email",
      "enter": "Enter",
      "env": "Environment",
      "expire": "Expire",
      "feature": "Feature",
      "filter": "Filter",
      "final": "Final",
      "games": "Games",
      "generate": "Generate",
      "getstarted": "Get Started",
      "gotit": "Got it!",
      "heal": "Heal",
      "home": "Home",
      "hour": "Hour",
      "image": "Image",
      "impersonate": "Impersonate",
      "import": "Import",
      "importing": "Importing",
      "ingame": "In Game",
      "legacy": "Legacy",
      "loading": "Loading",
      "location": "Location",
      "logo": "Stattracker Live Logo",
      "maxVidQuality": "Streaming Video Quality",
      "migrate": "Migrate",
      "name": "Name",
      "no": "No",
      "nodata": "No Data",
      "notes": "Notes",
      "novideos": "You don't have any uploaded videos!",
      "number": "Number",
      "ok": "Ok",
      "opponent": "Opponent",
      "pages": "Public Pages",
      "password": "Password",
      "passwordReset": "Password Reset",
      "pause": "Pause",
      "perGB": "/GB",
      "perMo": "/Mo",
      "perYr": "/Yr",
      "period": "Period",
      "playByPlay": "Play by Play",
      "prepaid": "Prepaid",
      "process": "Process",
      "processing": "Film is processing",
      "promote": "Promote",
      "public": "Public",
      "reels": "Reels",
      "refresh": "Refresh",
      "remove": "Remove",
      "reports": "Reports",
      "reset": "Reset",
      "restore": "Restore",
      "resume": "Resume",
      "revoke": "Revoke",
      "save": "Save",
      "saving": "Saving",
      "schedule": "Schedule",
      "score": "Score",
      "share": "Share",
      "showFullBoxScore": "Show Full Box Score",
      "showSimpleBoxScore": "Show Simple Box Score",
      "source": "Source",
      "stLive": "Stattracker Live",
      "standings": "Standings",
      "startGame": "Start Game",
      "statFormat": "Stat Format",
      "stattracker": "Stattracker",
      "stattrackerLive": "Stattracker <em>Live</em>",
      "status": "Status",
      "stop": "Discontinue",
      "super": "Super",
      "tags": "Tags",
      "teamSeasons": "Team Seasons",
      "time": "Time",
      "token": "Token",
      "track": "Track",
      "undefined": "Not Set",
      "updateRoster": "Add to game roster",
      "upload": "Upload",
      "uploadFilm": "Upload Film",
      "uploading": "Film is uploading",
      "usage": "Usage",
      "videoQuality": "Video Quality",
      "view": "View",
      "visitor": "Visitor",
      "visuals": "Visuals",
      "vs": "vs.",
      "welcome": "Welcome to Stattracker Live",
      "yes": "Yes"
    },
    "components": {
      "cp": {
        "aero": "Blue",
        "black": "Black",
        "brown": "Brown",
        "cardinal": "Cardinal",
        "carolina": "LtBlue",
        "ceramic": "LtOrange",
        "charcoal": "Charcoal",
        "crimson": "Crimson",
        "darkgreen": "DkGreen",
        "desert": "DkOrange",
        "gold": "Gold",
        "grey": "Grey",
        "kelly": "Kelly",
        "lemon": "Lemon",
        "maroon": "Maroon",
        "navy": "Navy",
        "orange": "Orange",
        "pink": "Pink",
        "purple": "Purple",
        "royal": "Royal",
        "scarlet": "Scarlet",
        "teal": "Teal",
        "vegas": "Vegas",
        "white": "White"
      },
      "filter": {
        "download": "Filter Downloads",
        "event": "Filter Events"
      },
      "tc": {
        "h0": "12am",
        "h1": "1am",
        "h10": "10am",
        "h11": "11am",
        "h12": "12pm",
        "h13": "1pm",
        "h14": "2pm",
        "h15": "3pm",
        "h16": "4pm",
        "h17": "5pm",
        "h18": "6pm",
        "h19": "7pm",
        "h2": "2am",
        "h20": "8pm",
        "h21": "9pm",
        "h22": "10pm",
        "h23": "11pm",
        "h3": "3am",
        "h4": "4am",
        "h5": "5am",
        "h6": "6am",
        "h7": "7am",
        "h8": "8am",
        "h9": "9am",
        "m0": ":00",
        "m10": ":10",
        "m15": ":15",
        "m20": ":20",
        "m25": ":25",
        "m30": ":30",
        "m35": ":35",
        "m40": ":40",
        "m45": ":45",
        "m5": ":05",
        "m50": ":50",
        "m55": ":55"
      }
    },
    "event": {
      "ast": "Assist",
      "blk": "Blocked Shot",
      "blk.rec": "Block Received",
      "cd": "Charge Taken",
      "def": "Deflection",
      "details": "Details",
      "edit": "Edit Event",
      "fg": {
        "2p": "Two Pointer",
        "2p_": "Missed Two Pointer",
        "2pm": "Made Two Pointer",
        "3p": "Three Pointer",
        "3p_": "Missed Three Pointer",
        "3pm": "Made Three Pointer",
        "cmn": "Field Goal",
        "fb": "Fastbreak",
        "fg_": "Missed Field Goal",
        "fgm": "Made Field Goal",
        "ft": "Free Throw",
        "ft_": "Missed Free Throw",
        "ftm": "Made Free Throw",
        "made": "Made",
        "miss": "Missed",
        "pot": "Off turnover",
        "scp": "Putback"
      },
      "foul": {
        "cmn": "Foul",
        "d": "Disqualifying Foul",
        "f": "Flagrant Foul",
        "i": "Intentional Foul",
        "o": "Offensive Foul",
        "p": "Personal Foul",
        "rec": "Foul Received",
        "t": "Technical Foul"
      },
      "noPlayer": "No Player",
      "other": "Other",
      "poss": {
        "cmn": "Possession",
        "tieup": "Tie up",
        "tip": "Tip",
        "undefined": "Alternating Possession"
      },
      "reb": {
        "cmn": "Rebound",
        "d": "Defensive Rebound",
        "o": "Offensive Rebound"
      },
      "stl": "Steal",
      "sub": {
        "cmn": "Substitution",
        "in": "Entered the game",
        "out": "Exited the game"
      },
      "time": "Time",
      "tmt": {
        "cmb": "Media Timeout",
        "cmn": "Timeout",
        "s20": "20s Timeout",
        "s30": "30s Timeout",
        "s60": "Full Timeout"
      },
      "to": "Turn Over",
      "type": "Type"
    },
    "form": {
      "save": {
        "error": {
          "details": "Try again; if error persists contact support",
          "title": "Save Failed"
        },
        "success": {
          "details": "All data is saved",
          "title": "Saved Successfully"
        }
      }
    },
    "game": {
      "addReel": "Add Reel",
      "allowTimeTracking": "Allow Time Tracking",
      "awayTeam": "Away Team",
      "courtType": "Court Type",
      "deletePrompt": "Are you sure you want to delete this game?",
      "edit": "Edit Game",
      "finalizing": "Finalizing",
      "flipEnds": "Flip Ends",
      "homeTeam": "Home Team",
      "resetFouls": "Reset Fouls",
      "sameTeamHeader": "Game cannot have the same team for both home and away teams.",
      "sameTeamInstruction": "Select a different team for either your home or away team.",
      "status": {
        "cancelled": "Cancelled",
        "edited": "Edited",
        "finished": "Final",
        "live": "Live",
        "pending": "Breakdown in Progress",
        "reporting": "Reporting",
        "scheduled": "Scheduled"
      },
      "uploadVideo": "Add game film"
    },
    "info": {
      "noActiveSeason": "No Active Season",
      "noDivision": "No Division",
      "noGames": "No games for this account",
      "noLeague": "No League",
      "noSchedule": "No scheduled games",
      "noStandings": "No Standings available"
    },
    "key": {
      "add": "Add API Key"
    },
    "live": {
      "TOL": "TOL: ",
      "finalize": "Finish Game",
      "finalized": "This game has finished and we are generating reports...",
      "fouls": "Fouls: ",
      "override": "Use box score instead of event data for reports"
    },
    "models": {
      "account": {
        "billingEmail": "Billing Email",
        "billingInfo": "Billing Information",
        "cultureName": "Language",
        "isAPIAvailable": "Allow API Access",
        "isPublic": "Enable Public Pages",
        "name": "Account",
        "profile": "User Profile",
        "publicReports": "Include Reports",
        "publicVideo": "Include Video",
        "timeZone": "Timezone",
        "users": "Users"
      },
      "breakdown": "Breakdown",
      "club": "Club",
      "download": "Download",
      "key": {
        "displayName": "API Key"
      },
      "keys": "API Keys",
      "player": "Player",
      "prepaidBreakdown": "Prepaid Breakdown",
      "season": "Season",
      "slug": {
        "displayName": "Name",
        "publicUrl": "Link",
        "season": "League",
        "team": "Team"
      },
      "slugs": "Public Pages",
      "team": "Club",
      "video": "Video"
    },
    "password": {
      "changed": "Your password was successfully changed",
      "emailRequired": "An email is required for a password reset",
      "old": "Old Password",
      "resetDescription": "If the email below is on file we will send a password reset",
      "resetSent": "A password reset email has been sent to"
    },
    "periods": {
      "length": "Period Length",
      "name": "Periods",
      "ot": {
        "_0": "OT1",
        "_1": "OT2",
        "_2": "OT3",
        "_3": "OT4",
        "_4": "OT5",
        "_5": "OT6",
        "_6": "OT7",
        "_7": "OT8",
        "_8": "OT9",
        "_9": "OT10"
      },
      "otLength": "Overtime Length",
      "regulation": {
        "_0": "1st",
        "_1": "2nd",
        "_2": "3rd",
        "_3": "4th",
        "_4": "5th",
        "_5": "6th",
        "_6": "7th",
        "_7": "8th",
        "_8": "9th",
        "_9": "10th"
      }
    },
    "plan": {
      "blurb": "Thanks for signing up!  All new accounts start out as \"Free\" Accounts.  Please consider supporting Stattracker Live's development by subscribing. Our subscription levels enjoy the following:",
      "cancelBlurb": "You may completely close your account at any time.  If you choose to close your account all stored data will be removed.",
      "cancellation": {
        "android": "<br><b>Your subscription is managed by the Google Play Store. Follow these steps to cancel the Stattracker Live subscription on your device:</b><ol><li>Launch the Google Play Store App</li><li>Tap \"Menu\":\"My Apps\":\"Subscriptions\" and then choose \"Stattracker Live\"</li><li>Tap \"Cancel\"</li></ol>",
        "hachisoft": "<br><b>Your subscription is managed by Hachisoft.  Contact support for assistance.</b>",
        "hachisoft_beta": "<br><b>Your subscription is managed by Hachisoft.  Contact support for assistance.</b>",
        "ios": "<br><b>Your subscription is managed by the iTunes App Store. Follow these steps to cancel the Stattracker Live subscription on your device:</b><ol><li>Tap \"Settings\" from your device home</li><li>Scroll down and tap \"iTunes & App Store\"</li><li>Tap your Apple ID</li><li>Tap \"View Apple ID\"</li><li>Scroll down and tap \"Subscriptions\"</li><li>Select \"Stattracker Live\" and turn off the auto-renewal option</li></ol>",
        "stripe": ""
      },
      "features": {
        "breakdown": "Coaches can breakdown game film with our specialized breakdown tools and create detailed reports",
        "film": "Upload, review, and breakdown film",
        "gameReport": "HTML game reports",
        "heatMap": "Heat map and waterfall stat displays",
        "invite": "Invite coaches and statisticians to join your account without sharing personal credentials",
        "leaders": "League leaders reports",
        "offDefRating": "Offensive and Defensive Rating reports",
        "pdf": "PDF versions of all reports",
        "playByPlay": "Play by Play reports",
        "playerCareerReport": "Player Career reports",
        "public": "Fans can follow live games with full stats & shot chart via public pages",
        "standings": "League standings and reports",
        "sync": "Full synchronization across the web, iOS and Android devices",
        "webTrack": "Track games via the web",
        "xlsx": "XLSX versions of all reports"
      },
      "free": "Free Plan",
      "freeBlurb": "Hachisoft provides a free-tier that allows personal use of your Stattracker Live app.  Would you like to move to the personal tier?",
      "name": "Plan",
      "noVideo": "No Video",
      "noVideoBlurb": "You may discontinue our video subscription at any time.  Would you like to stop using video?  All stored video will be removed.",
      "selected": "Subscribed",
      "type": {
        "club": "Club",
        "free": "Free",
        "league": "League"
      },
      "video": {
        "tier1": "1 to 50 gigabytes",
        "tier2": "51 to 150 gigabytes",
        "tier3": "151 to 250 gigabytes",
        "tier4": "251 to 350 gigabytes",
        "tier5": "351 to 450 gigabytes",
        "tier6": "451 gigabytes or more"
      },
      "videoBlurb": "Hachisoft offers full video integration on a metered subscription.  You only pay for what you use, and this covers the storage and video processing. Additionally, a video subscription is required for game film breakdowns."
    },
    "player": {
      "add": "Add New Player",
      "careerStats": "Career Stats",
      "edit": {
        "player": "Edit Player",
        "uploadImage": "Drag here or click to upload a player image"
      },
      "fields": {
        "awayNumber": "Away Number",
        "firstName": "First Name",
        "height": "Height",
        "hometown": "Hometown",
        "lastName": "Last Name",
        "number": "Number",
        "phone": "Phone",
        "weight": "Weight",
        "year": "Year"
      },
      "importText": {
        "details": "Click the button to import existing players from any other team.",
        "header": "Save yourself time by importing already existing players"
      },
      "stats": "Player Stats"
    },
    "playerSeason": {
      "add": {
        "player": "Add Player",
        "team": "Add Team Season"
      },
      "missingText": {
        "details": {
          "player": "Create a new player by clicking the button below.",
          "team": "Create a new club by clicking the button below."
        },
        "header": {
          "player": "Don't see the player you need?",
          "team": "Don't see the club you need?"
        }
      },
      "to": "To"
    },
    "playerseason": {
      "edit": "Edit Player Season"
    },
    "price_banner": "The {product} costs {price, number, USD}",
    "prompts": {
      "breakdownNotes": "Please provide any additional information you can help us to breakdown this film as successfully as possible.  Please note an issues with video (operator, battery), any roster number discrepancies (Bob Woodard is wearing 2 instead of 4 this game), or other information you deem pertinent.",
      "deleteKey": "Are you sure you want to delete this API key?",
      "deleteSeason": "Are you sure you want to delete this season?",
      "deleteSlug": "Are you sure you want to delete this public page?",
      "deleteTeam": "Are you sure you want to delete this team?",
      "deleteVideo": "Are you sure you want to delete this video?",
      "discontinue": "Discontinue Stattracker Live",
      "downloadApp": "Download the Stattracker Live app, start a new season, or schedule a game to get started.",
      "finalizeGame": "Games must be finalized for standings to be generated.",
      "noAccount": "Don't have an account?",
      "passwordReset": "Forgot your password?",
      "reqMoreTeams": "This season does not have enough teams to add a game"
    },
    "reportTypes": {
      "long": {
        "GameComboEfficiency": "Game Combo Efficiency",
        "GamePlayerEfficiency": "Game Player Efficiency",
        "GameReport": "Game Report",
        "OffDefRating": "Offense Defense Rating",
        "PlayByPlay": "Play by Play",
        "PlayerCareerReport": "Player Career Report",
        "PlayerCombos": "Player Combinations",
        "PlayerReport": "Player Report",
        "SeasonLeaders": "Season Leaders",
        "SeasonStandings": "Season Standings",
        "TeamReport": "Team Report"
      },
      "short": {
        "HTML": "HTML",
        "Json": "JSON",
        "MaxPreps": "MaxPreps",
        "PDF": "PDF",
        "XLSX": "XLSX"
      }
    },
    "season": {
      "add": "Add New Season",
      "edit": {
        "season": "Edit Season",
        "uploadImage": "Drag here or click to upload an image"
      },
      "fields": {
        "start": "Start",
        "type": "Type"
      },
      "scheduleGame": "Schedule a Game"
    },
    "slug": {
      "add": "Add New Public Page",
      "edit": "Edit Public Page"
    },
    "stats": {
      "_2pma": "2 Point Field Goals made - 2 Point Field Goals attempted.",
      "_2pp": "Percentage of 2 Point Field Goals made.",
      "_3pma": "3 Point Field Goals made - 3 Point Field Goals attempted.",
      "_3pp": "Percentage of 3 Point Field Goals made.",
      "abbrs": {
        "AST": "AST",
        "BLK": "BLK",
        "BLKR": "BLKR",
        "CD": "CD",
        "DEF": "DEF",
        "DR": "DR",
        "OR": "OR",
        "PF": "PF",
        "PFR": "PFR",
        "STL": "STL",
        "SUB": "SUB",
        "TIEUP": "TIEUP",
        "TIP": "TIP",
        "TO": "TO",
        "_2pma": "2PM-A",
        "_2pp": "2P%",
        "_3pma": "3PM-A",
        "_3pp": "3P%",
        "ast": "AST",
        "astTo": "AST/TO",
        "blk": "BLK",
        "blkr": "BLKR",
        "cd": "CD",
        "def": "DEF",
        "dr": "DR",
        "eff": "EFF",
        "efgp": "EFG%",
        "fbp": "FBP",
        "fgma": "FGM-A",
        "fgp": "FG%",
        "ftma": "FTM-A",
        "ftp": "FT%",
        "gameName": "Game",
        "gp": "GP",
        "min": "MP",
        "name": "Name",
        "num": "No.",
        "or": "OR",
        "pf": "PF",
        "pfr": "PFR",
        "plusMinus": "+/-",
        "pot": "POT",
        "ppg": "PPG",
        "pts": "PTS",
        "reb": "REB",
        "scp": "SCP",
        "stl": "STL",
        "sub": "SUB",
        "tieup": "TIEUP",
        "tip": "TIP",
        "to": "TO",
        "tsp": "TS%"
      },
      "ast": "Assists, where an offensive player passes a ball that results in a field goal.",
      "astTo": "Ratio of Assists to Turnovers.",
      "blk": "Blocked Shots, where the defensive player legally deflects a field goal attempt.",
      "blkr": "Blocks Received, where a player has their shot legally deflected.",
      "cancel": "cancel",
      "cd": "Defensive Charge taken, when a defensive player keeps and established position and is fouled by an offensive player.",
      "def": "Defensive Deflections, where a defensive player deflects a ball but possession is not changed.",
      "dr": "Defensive Rebounds, where a defensive player successfully gains possession of the ball after a missed shot attempt.",
      "eff": "Efficiency, statistical benchmark for comparing the overall value of a player calculated as (PTS+REB+AST+STL+BLK-FG missed-FT missed-TO).",
      "efgp": "Effective Field Goal Percentage",
      "fbp": "The number of points scored on a fast break",
      "fgma": "Field Goals made - Field Goals attempted.",
      "fgp": "Percentage of Field Goals made.",
      "ftma": "Free Throws made - Free Throws attempted.",
      "ftp": "Percentage of 3 Point Field Goals made.",
      "gameName": "Game",
      "gp": "Number of Games Played",
      "input": {
        "3p": {
          "f": "(f) fastbreak make",
          "q": "(q) putback make",
          "t": "(t) off turnover make",
          "v": "(v) fastbreak miss",
          "w": "(w) 3p make",
          "x": "(x) 3p miss",
          "y": "(y) off turnover miss",
          "z": "(z) putback miss"
        },
        "evt": {
          "a": "(a)ssist",
          "b": "(b)lock",
          "c": "(c)harge drawn",
          "e": "fr(e)ethrow",
          "f": "(f)oul",
          "g": "field (g)oal",
          "j": "(j) 3p",
          "k": "bloc(k) received",
          "o": "time(o)ut",
          "p": "(p)ossession",
          "r": "(r)ebound",
          "s": "(s)teal",
          "t": "(t)urnover",
          "u": "s(u)b"
        },
        "fg": {
          "f": "(f) fastbreak make",
          "q": "(q) putback make",
          "t": "(t) off turnover make",
          "v": "(v) fastbreak miss",
          "w": "(w) fg make",
          "x": "(x) fg miss",
          "y": "(y) off turnover miss",
          "z": "(z) putback miss"
        },
        "foul": {
          "d": "(d)isqualifying",
          "f": "(f)lagrant",
          "i": "(i)ntentional",
          "o": "(o)ffensive",
          "p": "(p)ersonal",
          "r": "(r)ecieved",
          "t": "(t)echnical"
        },
        "ft": {
          "w": "(w) ft make",
          "x": "(x) ft miss"
        },
        "player": {
          "#": "player number: {numbers}",
          "number": "player number: "
        },
        "player2": {
          "#": "player number: {numbers}",
          "a": "(a)ssist",
          "b": "(b)lock",
          "c": "(c)harge drawn",
          "e": "fr(e)ethrow",
          "f": "(f)oul",
          "g": "field (g)oal",
          "j": "(j) 3p",
          "k": "bloc(k) received",
          "o": "time(o)ut",
          "p": "(p)ossession",
          "r": "(r)ebound",
          "s": "(s)teal",
          "t": "(t)urnover",
          "u": "s(u)b"
        },
        "poss": {
          "t": "(t)ip",
          "x": "(x) tie-up"
        },
        "reb": {
          "d": "(d)efensive",
          "o": "(o)ffensive"
        },
        "team": {
          "h": "(h)ome",
          "v": "(v)isitor"
        },
        "tmt": {
          "2": "(2)0 second",
          "3": "(3)0 second",
          "6": "(6)0 second",
          "m": "(m)edia"
        }
      },
      "min": "Minutes Played",
      "name": "Name",
      "num": "Jersey Number",
      "or": "Offensive Rebounds, where a offensive player successfully gains possession of the ball after a missed shot attempt.",
      "pf": "Personal Fouls, is a breach of the rules that concerns illegal personal contact with an opposing player.",
      "pfr": "Fouls Received, number of times a player is fouled",
      "plusMinus": "The +/- stat represents team performance while a player is in the game. If the home team hits a 2 pointer, this would make the +/- for the checked in players on the home team have a +2 and the checked in players for the away team have a -2.",
      "pot": "The number of points scored as a result of turnovers by the opposing team",
      "ppg": "Points per Game Played",
      "pts": "The amount of points scored with a player or team.",
      "reb": "Total Rebounds, which includes both Offensive and Defensive rebounds.",
      "scp": "The number of points scored after an offensive rebound",
      "stl": "Steals, where a defensive player causes a turn over by their agressive action.",
      "to": "Turnovers, where an offensive player looses possession to a defensive player.",
      "tsp": "True Shooting Percentage"
    },
    "team": {
      "add": "Add New Club",
      "edit": {
        "team": "Edit Club",
        "uploadImage": "Drag here or click to upload a team image",
        "uploadLogo": "Drag here or click to upload a team logo"
      },
      "fields": {
        "abbr": "Abbreviation",
        "awayColor": "Away Color",
        "division": "Division",
        "homeColor": "Home Color",
        "league": "League",
        "logo": "Logo",
        "mascot": "Mascot",
        "tags": "Tags"
      }
    },
    "teamGame": {
      "stats": "Team Stats"
    },
    "teamSeason": {
      "add": {
        "player": "Add Player",
        "season": "Add Season",
        "team": "Add Team Season"
      },
      "edit": {
        "teamSeason": "Edit Team Season",
        "uploadImage": "Drag here or click to upload a team image",
        "uploadLogo": "Drag here or click to upload a team logo"
      },
      "missingText": {
        "details": {
          "player": "Create a new player by clicking the button below.",
          "season": "Create a new season by clicking the button below.",
          "team": "Create a new club by clicking the button below."
        },
        "header": {
          "player": "Don't see the player you need?",
          "season": "Don't see the season you need?",
          "team": "Don't see the club you need?"
        }
      },
      "to": "To"
    },
    "timing": {
      "adjustClip": "Adjust Clip",
      "clipStart": "Clip Start",
      "clipStop": "Clip Stop",
      "clockStart": "Start",
      "clockStop": "Stop",
      "edit": "Timing Edit"
    },
    "titles": {
      "login": "Login to Stattracker",
      "signUp": "Add new Stattracker Account"
    },
    "translations": {
      "en-us": "English",
      "es-es": "Spanish"
    },
    "user": {
      "email": "Email",
      "firstName": "First Name",
      "lastName": "Last Name",
      "password": "Password",
      "profileImage": "Profile Image",
      "uploadImage": "Drag here or click to upload a profile image"
    }
  }], ["es-es", {
    "account": {
      "accountFor": "{Nombre} Cuenta",
      "addUser": "Añade un colaborador",
      "availableToAdministrators": "Esta página solo está disponible para los administradores de cuentas.",
      "availableToOwners": "Esta página solo está disponible para el propietario de la cuenta.",
      "availableToSuperAdministrators": "Esta página solo está disponible para superadministradores.",
      "notPubliclyAvailable": "Las descargas no están disponible públicamente",
      "resendInvite": "Reenviar invitación",
      "sendInvite": "Enviar invitación",
      "type": {
        "admin": "Administación",
        "collaborator": "Colaborador",
        "pending": "Pendiente"
      }
    },
    "actions": {
      "clipFilm": "Hacer clip de pelicula",
      "getStarted": "Empezar",
      "login": "Inciciar sesión en Stattracker",
      "startTracking": "Iniciar el seguimiento"
    },
    "app": {
      "account": "Configuraciones de la cuenta",
      "addGame": "agregar juego",
      "addNewSeasonFor": "Agregar nueva temporada para",
      "addRoster": "Añadir lista",
      "addSeason": "Añadir temporada",
      "addTeam": "Agregar club",
      "addVideo": "Añadir video",
      "advancedOptions": "Opciones Avanzadas",
      "analyzeBlurb": "Los informes estsdísticos en profundidad y la visualización de datosproporcionan las herramientas de análisis para mejorar su equipo.",
      "analyzeHeader": "",
      "breakdownBlurb": ", o haga que nuestro dedicado estadístico rompa su película de juegos a pedido.  Subes película, tedamos estadísticas.  Los costos dependen del nivel de detalle deseadoy del tiempo de respuesta requerido.",
      "closeAccount": "Cerrar cuenta",
      "confirmEmailChange": "Confirmar cambio de correo electrónico",
      "confirmPasswordChange": "Confirmar cambio de contraseña",
      "dashboard": "Tablero",
      "downgrade": "Degradar",
      "emailChanged": "Correo electrónico cambiado",
      "followBlurb": "No te pierdas una jugada con actualizaciones en tiemp real transmitido desde la línea de banda directamente a su dispositivo.",
      "followHeader": "Sigue los juegos de tu equipo en tiempo real",
      "freeVersion": "Versión gratuita",
      "gameAdd": "Agregar juego",
      "gameFilmBlurb": "Mira las mejores jugadas de tu equipo y determina los puntos de mejoras es cualquier dispositivo.",
      "gameFilmHeader": "Revisa la películar del juego en cualquier lugar",
      "gameScheduled": "Este juego está programado para p",
      "goToTeam": "Ir a mi equipo",
      "goToTeamHelp": "Ingrese el identificador de su equipo a continuación. Sin no está seguro de qué es, póngase en contacto con el coordinador de su equipo or Hachisoft Support.",
      "highlightPlayer": "Jugador destacado",
      "inThe": " en el ",
      "invalidEmail": "El correo electrónico proporcionado no es válido",
      "live": "Juegos libe",
      "login": "iniciar sesión",
      "logout": "Cerrar sesión",
      "makeAvailable": "Disponible en temporada",
      "maxUsageInPeriod": "*uso máximo en el mes",
      "meteredVideo": "Suscripción de uso de video",
      "multiDeviceAlt": "Seguimiento en tiempo real y de múltiples dispositivos",
      "noPublicPages": "¡No tienes ninguna pá¡gina pública!",
      "noReportData": "Termina de comenzar a ver tus visuales",
      "openingBlurb": "Estadísticas digitales intuitivas que puede capturar, analizar y camportir con solo tocar un botón",
      "playByPlayBlurb": "Juega por las estadísticas en tiempo real directamente desde tu dispositivo, para que tus amigos y familiares no se pierdan una jugada.",
      "premiumFeature": "Esta es uns función premium y no está disponible en el plan 'Gratis'.  ¡Considere ayudarnos a crear excelentes aplicaciones actualizando su cuenta hoy!",
      "pricePerGB": "Precio mensual por gigabyte",
      "pricing": "Precios",
      "privacy": "Política de privacidad",
      "refundBlurb": "Hachisoft respalda sus productos y servicios.  Si tiene un problema, comuníquese con support@hachisoft.com y haremos todo lo poaible para solucionar el problems.<br>Los reembolosos pueden ser prorrateados y pueden incluir tarifas de procesador.",
      "remember": "Recuerda mi elección",
      "report": {
        "buildHTML": "Construir HTML",
        "buildMaxPreps": "Construir Max Preps",
        "buildPDF": "Construir PDF",
        "buildXLSX": "Construir XLSX",
        "customFilter": "Personalizado",
        "filtered": "Informe Filtrado",
        "gameType": "Tipo de Juego",
        "leagueFilter": "Liga",
        "neutralFilter": "Neutral",
        "playoffFilter": "Eliminatorio",
        "settings": "Configuración de Informes"
      },
      "reporting": "Informes",
      "resetGameData": "Restablecer datos del juego",
      "resetPassword": "Restablecer la contraseña",
      "rosterForNewSeason": "Lista para la nueva temporada",
      "shareBlurb": "Comparta estadísticas, desgloses de pilículas, gráficos de tomas e informes con su equipo, liga o comunidad para todos estén al tanto.  controlar quien ve que.",
      "signUp": "Regístrate",
      "submitForBreakdown": "Enviar para desglose",
      "support": "Apoyo",
      "terms": "Términos y Condiciones",
      "trackHeader": "Estadísticas de pista en tiempo real",
      "upgrade": "Mejorar",
      "useGravatar": "Utilizar gravatar",
      "usePrepaid": "Usar prepago",
      "videoBlurb": "sube tanta película de juegos como quieras.  Nuestro modelo de suscripción solo le cobra poe el almacenamienro que utilza.",
      "watchLive": "Ver en directo"
    },
    "augment": {
      "description": "Stattracker de baloncesto necesita más información para finalizar tu cuenta."
    },
    "common": {
      "activePlayers": "Jugadores activos",
      "allSeasons": "Todas las temporadas",
      "allow": "Permitir",
      "analyze": "Analizar",
      "api": "API",
      "augment": "Información Adicional",
      "available": "Disponible",
      "backup": "Apoyo",
      "bench": "Banco",
      "bonus": "Primo",
      "boxscore": "Puntajes de caja",
      "cancel": "Cancelar",
      "change": "Cambio",
      "checkout": "Revisa",
      "clear": "Claro",
      "close": "Cerrar",
      "completed": "Terminado",
      "confirm": "Confirmar",
      "created": "Creado",
      "date": "Fecha",
      "day": "Día",
      "delete": "Borrar",
      "deleteall": "Eliminar todos",
      "demote": "Degradar",
      "description": "Descripción",
      "dest": "Destino",
      "doubleBonus": "doble bono",
      "downloads": "Descargas",
      "due": "Debido",
      "edit": "Editar",
      "email": "Email",
      "enter": "Entrar",
      "env": "Ambiente",
      "expire": "Expirar",
      "feature": "Característica",
      "filter": "Filtrar",
      "final": "Final",
      "games": "Juegos",
      "generate": "Generar",
      "getstarted": "Empezar",
      "gotit": "¡Levanta eso!",
      "heal": "Sanar",
      "home": "Casa",
      "hour": "Hora",
      "image": "Imagen",
      "impersonate": "Personificar",
      "import": "Importar",
      "importing": "Importadora",
      "ingame": "En el juego",
      "legacy": "Legado",
      "location": "Ubicación",
      "logo": "Stattracker Live Logo",
      "maxVidQuality": "Máxima calidad de video",
      "migrate": "Emigrar",
      "name": "Nombre",
      "no": "No",
      "nodata": "Sin datos",
      "notes": "Notas",
      "novideos": "¡No tienes ningún video subido!",
      "number": "Número",
      "ok": "Ok",
      "opponent": "Adversario",
      "pages": "Paginas Publicas",
      "password": "Contraseña",
      "passwordReset": "Restablecimiento de contrasseÃ±a",
      "pause": "Pausa",
      "perGB": "/GB",
      "perMo": "/Mo",
      "perYr": "/año",
      "period": "Período",
      "playByPlay": "Jugar por jugar",
      "prepaid": "Pagado por adelantado",
      "process": "Proceso",
      "processing": "La película se está procesando",
      "promote": "Promover",
      "public": "Pública",
      "reels": "Carretes",
      "refresh": "Refrescar",
      "remove": "Ratirar",
      "reports": "Informes",
      "reset": "Reiniciar",
      "restore": "Restaurar",
      "resume": "Currículum",
      "revoke": "Revocar",
      "save": "Salvar",
      "saving": "Ahorro",
      "schedule": "Programar",
      "score": "Puntuación",
      "share": "Compartir",
      "showFullBoxScore": "Mostrar las puntuaciones de la caja completa",
      "showSimpleBoxScore": "Mostrar puntaje simple",
      "source": "Fuente",
      "stLive": "Stattracker Live",
      "standings": "Posiciones",
      "startGame": "Empezar juego",
      "statFormat": "Formato de estadÃ­sticas",
      "stattracker": "Stattracker",
      "stattrackerLive": "Stattracker <em>Live</em>",
      "status": "Estado",
      "stop": "Interrumpir",
      "super": "Súper",
      "tags": "aetiquetas",
      "teamSeasons": "Temporadas de equipo",
      "time": "Hora",
      "token": "Simbólico",
      "track": "Pista",
      "undefined": "No establecido",
      "updateRoster": "Añadir a la lista de juegos",
      "upload": "Subir",
      "uploadFilm": "Subir Películar",
      "uploading": "La películar está subiendo",
      "usage": "Uso",
      "videoQuality": "Calidad de video",
      "view": "Ver",
      "visitor": "Visitantes",
      "visuals": "Visuales",
      "vs": "vs.",
      "welcome": "Bienvenido a Stattracker Live",
      "yes": "Sí"
    },
    "components": {
      "cp": {
        "aero": "Azul",
        "black": "Negro",
        "brown": "Marrón",
        "cardinal": "Cardenal",
        "carolina": "azul claro",
        "ceramic": "Naranja claro",
        "charcoal": "Carbón",
        "crimson": "Carmesí",
        "darkgreen": "Verde oscuro",
        "desert": "Naranja oscuro",
        "gold": "Oro",
        "grey": "Gris",
        "kelly": "Kelly",
        "lemon": "Lemón",
        "maroon": "Granate",
        "navy": "Armada",
        "orange": "Naranja",
        "pink": "Rosado",
        "purple": "Púrpura",
        "royal": "Real",
        "scarlet": "Escarlata",
        "teal": "Trullo",
        "vegas": "Vegas",
        "white": "Blanco"
      },
      "filter": {
        "download": "Download",
        "event": "Event"
      },
      "tc": {
        "h0": "12am",
        "h1": "1am",
        "h10": "10am",
        "h11": "11am",
        "h12": "12pm",
        "h13": "1pm",
        "h14": "2pm",
        "h15": "3pm",
        "h16": "4pm",
        "h17": "5pm",
        "h18": "6pm",
        "h19": "7pm",
        "h2": "2am",
        "h20": "8pm",
        "h21": "9pm",
        "h22": "10pm",
        "h23": "11pm",
        "h3": "3am",
        "h4": "4am",
        "h5": "5am",
        "h6": "6am",
        "h7": "7am",
        "h8": "8am",
        "h9": "9am",
        "m0": ":00",
        "m10": ":10",
        "m15": ":15",
        "m20": ":20",
        "m25": ":25",
        "m30": ":30",
        "m35": ":35",
        "m40": ":40",
        "m45": ":45",
        "m5": ":05",
        "m50": ":50",
        "m55": ":55"
      }
    },
    "event": {
      "ast": "Ayudar",
      "blk": "Disparo bloqueado",
      "blk.rec": "Bloque recibido",
      "cd": "Cargo tomado",
      "def": "Deviación",
      "details": "Detalles",
      "edit": "Editar evento",
      "fg": {
        "2p": "Dos punteros",
        "2p_": "Perdido do punteros",
        "2pm": "Hecho dos punteros",
        "3p": "Tres puntero",
        "3p_": "Perdido tres puntero",
        "3pm": "Hecho tres puntero",
        "cmn": "Gol de campo",
        "fb": "Descanso rapido",
        "fg_": "Gol de campo perdido",
        "fgm": "Gol de campo hecho",
        "ft": "Tiro libre",
        "ft_": "Tiro libre perdido",
        "ftm": "Tiro libre hecho",
        "made": "Hecho",
        "miss": "perder",
        "pot": "Fuera de rotación",
        "scp": "Afuera"
      },
      "foul": {
        "cmn": "Falta",
        "d": "Falta descalificadora",
        "f": "Falta flagrante",
        "i": "Falta intencional",
        "o": "Falta Ofensiva",
        "p": "Falta personal",
        "rec": "Falta recibida",
        "t": "Falta Técnica"
      },
      "noPlayer": "No jugador",
      "other": "Otro",
      "poss": {
        "cmn": "Posesión",
        "tieup": "atar",
        "tip": "Tip",
        "undefined": "Posesión Alternada"
      },
      "reb": {
        "cmn": "Rebote",
        "d": "Rebote defensivo",
        "o": "Rebote Ofensivo"
      },
      "stl": "Robar",
      "sub": {
        "cmn": "Sustitución",
        "in": "Entró en el juego",
        "out": "Salido del juego"
      },
      "time": "Hora",
      "tmt": {
        "cmb": "Tiiempo de espera de los medios",
        "cmn": "Se acaboó el tiempo",
        "s20": "20 segundo tiempo fuera ",
        "s30": "30 segundo tiempo fuera",
        "s60": "Tiempo de espera completo"
      },
      "to": "Entregar",
      "type": "Tipo"
    },
    "form": {
      "save": {
        "error": {
          "details": "Inténtalo de nuevo; Si el error persiste, contacte al soporte",
          "title": "Error al guardar"
        },
        "success": {
          "details": "Todos los datos se guardan",
          "title": "Guardado exitosamente"
        }
      }
    },
    "game": {
      "addReel": "Añadir carrete",
      "allowTimeTracking": "Permitir seguimiento de tiempo",
      "awayTeam": "Equipo de Fuera",
      "courtType": "Tipo de corte",
      "deletePrompt": "¿Estás seguro de que quieres eliminar este juego?",
      "edit": "Editar juego",
      "finalizing": "Finalizando",
      "flipEnds": "Extremos abatibles",
      "homeTeam": "Equipo Local",
      "resetFouls": "Reiniciar faltas",
      "sameTeamHeader": "El juego no puede tener el mismo equipo para los equipos local y visitante.",
      "sameTeamInstruction": "Seleccione un equipo diferente para su equipo local o visitante.",
      "status": {
        "cancelled": "Cancelado",
        "edited": "Editado",
        "finished": "Final",
        "live": "vivir",
        "pending": "Desglose en progreso",
        "reporting": "Informes",
        "scheduled": "Programado"
      },
      "uploadVideo": "Añadir película de juego"
    },
    "info": {
      "noActiveSeason": "No hay temporada activa",
      "noDivision": "Sin división",
      "noGames": "No hay juegos para eats cuenta",
      "noLeague": "Ninguna liga",
      "noSchedule": "Juegos no programados",
      "noStandings": "No hay posiciones disponibles"
    },
    "live": {
      "TOL": "TOL: ",
      "finalize": "Terminar juego",
      "finalized": "Este juego ha finalizado y estamos generando informes...",
      "fouls": "Faltas: ",
      "override": "Utilice el cuadro de puntuación en lugar de los datos del evento para los informes"
    },
    "models": {
      "account": {
        "billingEmail": "Correo Electrónico de Facturas",
        "billingInfo": "Datos de facturación",
        "cultureName": "Idioma",
        "isPublic": "Habilitar páginas públicas",
        "name": "Cuenta",
        "profile": "Perfil del usuario",
        "publicReports": "Incluir informes",
        "publicVideo": "Incluir video",
        "timeZone": "Timezone",
        "users": "Usuarios"
      },
      "breakdown": "Descompostura",
      "club": "Club",
      "download": "Descargar",
      "player": "Jugador",
      "prepaidBreakdown": "Desglose de prepago",
      "season": "Temporada",
      "slug": {
        "displayName": "Nombre",
        "publicUrl": "enlazar",
        "season": "Liga",
        "team": "Equipo"
      },
      "slugs": "Páginas públicas",
      "team": "Club",
      "video": "Vídeo"
    },
    "password": {
      "changed": "Tu contraseña ha sido exitosamente cambiada",
      "emailRequired": "Se requiere un correo electrónico para restablecerla contraseña",
      "old": "Contraseña anterior",
      "resetDescription": "Si el correo electrínico a está archivado, le enviaremos un resablecimient de contraaseña.",
      "resetSent": "Se ha enviado un correo electrónico de restablecimiento"
    },
    "periods": {
      "length": "Duración del período",
      "name": "Periodos",
      "ot": {
        "_0": "OT1",
        "_1": "OT2",
        "_2": "OT3",
        "_3": "OT4",
        "_4": "OT5",
        "_5": "OT6",
        "_6": "OT7",
        "_7": "OT8",
        "_8": "OT9",
        "_9": "OT10"
      },
      "otLength": "Duracioón en horas extras",
      "regulation": {
        "_0": "1st",
        "_1": "2nd",
        "_2": "3rd",
        "_3": "4th",
        "_4": "5th",
        "_5": "6th",
        "_6": "7th",
        "_7": "8th",
        "_8": "9th",
        "_9": "10th"
      }
    },
    "plan": {
      "blurb": "Gracias por registrarte!  Todas las cuentas nuevas comienzan como cuentas gratuitas . Haz cambios en la cuenta a través del menú en la parte superior derecha.",
      "cancelBlurb": "Puede cerrar completamente su cuenta en cualquier momento.  Si elige cerrar su cuenta, se eliminarán todos los datos almacenados.",
      "cancellation": {
        "android": "<br><b>Tu suscripción es administrada por Google Play Store. Siga estos pasos para cancelar la suscripción de Stattracker live en su dispositivo:</b><ol><li>Iniciar la aplicación Google play Store</li><li>Toque en \"Menú\":\"Mis aplicaciones\":\"Suscripciones\" y luego elegir \"Stattracker Live\"</li><li>Toque \"Cancelar\"</li></ol>",
        "hachisoft": "<br><b>Su suscripción es gestionada por Hachisoft.  Póngase en contacot con soporte para asistencia.</b>",
        "hachisoft_beta": "<br><b>Su suscripción es gestionada por Hachisoft.  Póngase en contacto con soporte para asistencia.</b>",
        "ios": "<br><b>Tu suscripción es administrada por la tienda de aplicaciones de Itunes. Siga estos pasos pars cancelar la suscripción de Stattracke Live en su dispositivo:</b><ol><li>Tap \"Ajustes\" desde tu dispositivo en casa</li><li>Desplá¡cese hacia abajo y toque \"iTunes & App Store\"</li><li>Toca tu ID de Apple</li><li>Tap \"Ver ID de Apple\"</li><li> \"Suscriociones\"</li><li>Seleccionar \"Stattracker Live\" y apaga la opción de renovación automática</li></ol>",
        "stripe": ""
      },
      "features": {
        "breakdown": "Los entrenadores pueden desglosar la película del juego con nuestras herramientas especializadas de desglose y crear inormes detallados",
        "film": "Subir, revisar y desglosar película",
        "gameReport": "Informes de Juegos HTML",
        "heatMap": "Mapa de calor y visualización de estadísticas en cascada",
        "invite": "invite a entrenadores y estadísticos a unirse a su cuenta sin compartir cresenciales personales",
        "leaders": "Informes de líderes de la liga",
        "offDefRating": "Calificiones ofensivas y defensivas",
        "pdf": "Versiones en PDF de todos los informes",
        "playByPlay": "Informes de juego por juego",
        "playerCareerReport": "Informes de carrera del jugador",
        "public": "Los fanáticos pueden seguir los partidos en vivo con estadísticas completas y el cuadro de disparos",
        "standings": "Clasificaciön e informes de la liga",
        "sync": "Sincronización completa en dispositivos web, IOS y Android",
        "webTrack": "Seguimiento de jugeos a través de la web",
        "xlsx": "Versiones XLSX de todos los informes"
      },
      "free": "Plan gratis",
      "freeBlurb": "Hachisoft proporciona un nivel gratuito que permite el uso personal de su aplicación Stattracker Live.  ¿Te gustarÃ­a pasar al nivel personal?",
      "name": "Plan",
      "noVideo": "No hay video",
      "noVideoBlurb": "Puedes suspender nuestra suscripción de video en cualquier momento.  ¿Te gustaría dejar de usar el video?  Todos los vedos almacenados será¡n eliminados.",
      "selected": "Suscrito",
      "type": {
        "club": "Club",
        "free": "Gratis",
        "league": "Liga"
      },
      "video": {
        "tier1": "1 to 50 gigabytes",
        "tier2": "51 to 150 gigabytes",
        "tier3": "151 to 250 gigabytes",
        "tier4": "251 to 350 gigabytes",
        "tier5": "351 to 450 gigabytes",
        "tier6": "451 gigabytes o más"
      },
      "videoBlurb": "Hachisoft ofrece integración completa de vidoe en una suscripción medida.  Solo paga por lo que usa, y esto cubre el almacenamiento el procesamiento de video. Además, se requiere una suscripción de video para desgloses de películas de juegos."
    },
    "player": {
      "add": "Agregar nuevo jugador",
      "careerStats": "Estadísticas de carrera",
      "edit": {
        "player": "Editar jugador",
        "uploadImage": "Arrastra aquí o haz clic para subir una imagen de jugador"
      },
      "fields": {
        "awayNumber": "Número ausente",
        "firstName": "Nombre de pila",
        "height": "Altura",
        "hometown": "Ciudad natal",
        "lastName": "Apellido",
        "number": "Número",
        "phone": "Teléfono",
        "weight": "Peso",
        "year": "Año"
      },
      "importText": {
        "details": "Haz clic el botón para importar jugadores existentes de cualquier otrp equipo.",
        "header": "Ahorra tiempo importando jugadores ya existentes"
      },
      "stats": "Estadísticas del jugador"
    },
    "playerSeason": {
      "add": {
        "player": "Añadir jugador",
        "team": "Añadir temporada de equipo"
      },
      "missingText": {
        "details": {
          "player": "Crea un nuevo jugador haciendo clic en el botón de abajo.",
          "team": "Crea un nuevo club haciendo clic el botón de abajo."
        },
        "header": {
          "player": "¿No ves el juagor que necesitas?",
          "team": "¿No ves el club que necesitas?"
        }
      },
      "to": "To"
    },
    "playerseason": {
      "edit": "Editar temporada del jugador"
    },
    "prompts": {
      "breakdownNotes": "Por favor, proporcione cualquier información adicional que nos pueda ayudar a desglosar esta película de la manera más exitosa posible.  Tenga en cuenta los problemas con el video  (operador, batería), cualquier discrepancia en el número de la lista (Bob Woodard usa 2 en lugar de 4 en esta juego), u otra información que considere pertinente.",
      "deleteSeason": "¿Seguro que quieres borrar eats temporada?",
      "deleteSlug": "¿Estás seguro de que quieres aliminar esta página pública?",
      "deleteTeam": "¿Estás seguro de que quieres eliminar este equipo?",
      "deleteVideo": "¿Seguro que quieres borrar este video?",
      "discontinue": "Descontinuar Stattracker Live",
      "downloadApp": "Descargue la aplicación Stattracker Live, comience una nueva tempporada o programe un juego para comenzar.",
      "finalizeGame": "Los juegos deben ser finalizados para que se genere la clasificación.",
      "noAccount": "¿No tienes una cuenta?",
      "passwordReset": "¿Olvidaste tu contraseña?",
      "reqMoreTeams": "Esta temporada no tiene suficientes equipos para agregar un juego"
    },
    "reportTypes": {
      "long": {
        "GameComboEfficiency": "Eficiencia de juego combinado",
        "GamePlayerEfficiency": "Eficiencia del jugador del juego",
        "GameReport": "Informe del Juego",
        "OffDefRating": "Calificación de defensa de la ofensa",
        "PlayByPlay": "Jugar por Jugar",
        "PlayerCareerReport": "Carrera del jugador",
        "PlayerCombos": "Combinaciones de jugadores",
        "PlayerReport": "Informe del jugador",
        "SeasonLeaders": "Líderes de temporada",
        "SeasonStandings": "Posiciones de la temporada",
        "TeamReport": "Informe del Equipo"
      },
      "short": {
        "HTML": "HTML",
        "Json": "JSON",
        "MaxPreps": "MaxPreps",
        "PDF": "PDF",
        "XLSX": "XLSX"
      }
    },
    "season": {
      "add": "Añadir nueva temporada",
      "edit": {
        "season": "Editar temporada",
        "uploadImage": "Arrastra aquí o haz click para subir una imagen"
      },
      "fields": {
        "start": "Comienzo",
        "type": "Tipo"
      },
      "scheduleGame": "Programar unn juego"
    },
    "slug": {
      "add": "Añadir nueva página pública",
      "edit": "Editar Página pública"
    },
    "stats": {
      "_2pma": "Metas de campo de 2 puntos realizadas - Metas de campo de 2 puntos intentadas.",
      "_2pp": "Porcentaje de 2 goles de campo de puntos realizados.",
      "_3pma": "Metas de campo 3 puntos realizadas - Metas de campo de 3 puntos intentadas.",
      "_3pp": "Porcentaje de objetivos de campo de 3 puntos realizados.",
      "abbrs": {
        "AST": "AST",
        "BLK": "BLOQ",
        "BLKR": "BLOQR",
        "CD": "CD",
        "DEF": "DEF",
        "DR": "DR",
        "OR": "RO",
        "PF": "PA",
        "PFR": "PAR",
        "STL": "ROB",
        "SUB": "SUS",
        "TIEUP": "Atar",
        "TIP": "TIP",
        "TO": "PE",
        "_2pma": "2TC-I",
        "_2pp": "2P%",
        "_3pma": "3PC-I",
        "_3pp": "3P%",
        "ast": "AST",
        "astTo": "AST/TO",
        "blk": "BLOQ",
        "blkr": "BLOQR",
        "cd": "CD",
        "def": "DEF",
        "dr": "DR",
        "eff": "EF",
        "efgp": "EFG%",
        "fbp": "FBP",
        "fgma": "TCC-I",
        "fgp": "TC%",
        "ftma": "TLI-C",
        "ftp": "TL%",
        "gameName": "Juego",
        "gp": "JJ",
        "min": "MP",
        "name": "Nombre",
        "num": "No.",
        "or": "RO",
        "pf": "PA",
        "pfr": "PAR",
        "plusMinus": "+/-",
        "pot": "PPE",
        "ppg": "PPG",
        "pts": "PTS",
        "reb": "REB",
        "scp": "SCP",
        "stl": "ROB",
        "sub": "SUB",
        "tieup": "TIEUP",
        "tip": "TIP",
        "to": "PE"
      },
      "ast": "Asistencias, donde un jugador ofensivo pasa un pelota qur resulta en un gol de campo.",
      "astTo": "Proporción de asistencias a pérdidas de balón.",
      "blk": "Disparos bloqueados, donde el jugador defensivo esvía legalmente un intento de golde campo.",
      "blkr": "Bloques recibidos, doned un jugador tiene su disparo desviado legalmente.",
      "cancel": "cancelar",
      "cd": "Carga defensiva tomada, cuando un jugador defensivo mantiene una posición establecida y recibe una falta de un jugador ofensivo.",
      "def": "Deflexiones defensivas, donde un jugador defensivo desvía una bola pero no se cambia la posesión.",
      "dr": "Rebotes defensivos, donde un jugador defensivo gag con éxito la posesión de la pelota después de un intento fallido de tiro.",
      "eff": "Eficiencia, punto de referencia estadístico para comparar el valor general de un jugador calculado como (PTS+REB+AST+Rob+BLOQ-TC perdido-TL perdido-PE).",
      "efgp": "Porcentaje de objetivo de campo efectivo",
      "fbp": "El número de puntos anotados en un descanso rápido",
      "fgma": "Metas de campo hechas-Metas de campo intentadas.",
      "fgp": "Porcentaje de goles de campo realizados.",
      "ftma": "Tiros libres realizados - Tiros libres intentados.",
      "ftp": "Porcentaje de objetivos de campo de 3 puntos realizados.",
      "gameName": "Juego",
      "gp": "Número de juegos jugados",
      "input": {
        "3p": {
          "f": "(f) Descanso rapido Hacer",
          "q": "(q) Afuera hacer",
          "t": "(t) off turnover make",
          "v": "(v) Descanso rapido perder",
          "w": "(w) 3p hacer",
          "x": "(x) 3p perder",
          "y": "(y) off turnover miss",
          "z": "(z) Afuera perder"
        },
        "evt": {
          "a": "(a)yudar",
          "b": "(b)loquear",
          "c": "(c)arga dibujada",
          "e": "Tiro (l)ibre",
          "f": "(f)alta",
          "g": "(g)ol de campo",
          "j": "(j) 3p",
          "k": "Blo(q)ue recibido",
          "o": "se acabí el tiempo",
          "p": "(p)osesión",
          "r": "(r)ebote",
          "s": "(r)obar",
          "t": "Volumen de (n)egocios",
          "u": "s(u)stituir"
        },
        "fg": {
          "f": "(f) Descanso rapido Hacer",
          "q": "(q) Afuera hacer",
          "t": "(t) off turnover make",
          "v": "(v) Descanso rapido perder",
          "w": "(w) TC Hacer",
          "x": "(x) TC perder",
          "y": "(y) off turnover miss",
          "z": "(z) Afuera perder"
        },
        "foul": {
          "d": "(d)escalificante",
          "f": "(f)lagrante",
          "i": "(i)ntencional",
          "o": "(o)fensivo",
          "p": "(p)ersonal",
          "r": "(r)ecibido",
          "t": "(t)écnico"
        },
        "ft": {
          "w": "(w) Tl Hacer",
          "x": "(x) TL Perder"
        },
        "player": {
          "number": "número  de jugador: "
        },
        "poss": {
          "t": "(p)ropina",
          "x": "(x) atar"
        },
        "reb": {
          "d": "(d)efensivo",
          "o": "(o)fensivo"
        },
        "team": {
          "h": "casa",
          "v": "visitante"
        },
        "tmt": {
          "2": "(2)0 segundo",
          "3": "(3)0 segundo",
          "6": "(6)0 segundo",
          "m": "(m)edios de comunicaciÃ³n"
        }
      },
      "min": "Minutos jugados",
      "name": "Nombre",
      "num": "Número de camiseta",
      "or": "Rebotes ofensivos, donde un jugador ofensivo obtiene la posesión de la pelota luego de un intento fallido.",
      "pf": "Faltas personales, es una infracción de las reglas que se relacionan con el contacto personal ilegal con un jugador contrario.",
      "pfr": "Faltas recibidas, número de veces que in jugador recibe una falta",
      "plusMinus": "La esadística +/-  representa el rendimiento del equipo mientras un jugador está en el juego. Siel equipo local golpea 2 puntos, eso haría que +/- pars los jugadores registrados en el equipo local tenga un +2 y los juadores registrados para el equipo vistante tengan un -2.",
      "pot": "La cantidad de puntos anotados como resultado de las pérdidas de balón por parte del equipo contrario",
      "ppg": "Puntos por juego jugado",
      "pts": "La canitdad de puntos anotados con un jugador o equipo.",
      "reb": "Rebotes totales, que incluyen tanto rebotes ofensivos como defensivos.",
      "scp": "El número d epuntos anotados después de un rebote ofensivo",
      "stl": "Roba, donde un jugador defensivo provoca un vuelco por su accioón agresiva.",
      "to": "Rotaciones, doned un jugador ofensivo pierde la posesión de jugador defensivo."
    },
    "team": {
      "add": "agregar nuevo club",
      "edit": {
        "team": "Club de edición",
        "uploadImage": "Arrastra aquí o haz click para subir una imagen de equipo",
        "uploadLogo": "Arrastra aquí o haz clic para subir el logo de un equipo"
      },
      "fields": {
        "abbr": "Abreviatura",
        "awayColor": "Color ausente",
        "division": "Divisón",
        "homeColor": "Color de la casa",
        "league": "Liga",
        "logo": "Logo",
        "mascot": "Mascota",
        "tags": "Etiquetas"
      }
    },
    "teamSeason": {
      "add": {
        "player": "Agregar jugador",
        "season": "Añadir Temporada",
        "team": "Añadir temporada de equipo"
      },
      "edit": {
        "teamSeason": "Editar temporada de equipo",
        "uploadImage": "Arrastra aquií o haz click para subir una imagen de equipo",
        "uploadLogo": "Arrastra aquí o haz clic para subir el logo de un equipo"
      },
      "missingText": {
        "details": {
          "player": "Crea un nuevo jugador haciendo clic en el botón de abajo.",
          "season": "Crea una nueva temporada haciendo clic en el botón de abajo.",
          "team": "Crea un nuevo club haciendo clic en el botón de abajo."
        },
        "header": {
          "player": "¿No ves el jugador que necesitas?",
          "season": "¿No ves la temporada que necesitas?",
          "team": "¿No ves el club que necesitas?"
        }
      },
      "to": "To"
    },
    "timing": {
      "adjustClip": "Ajustar clip",
      "clipStart": "Inicio del clip",
      "clipStop": "parada de clip",
      "clockStart": "Comienzo",
      "clockStop": "Detener",
      "edit": "Edición de tiempo"
    },
    "titles": {
      "login": "Iniciar sesión en Stattracker",
      "signUp": "Añdir una nueva cuenta de Stattracker"
    },
    "translations": {
      "en-us": "Inglés",
      "es-es": "Español"
    },
    "user": {
      "email": "Email",
      "firstName": "Nombre de pila",
      "lastName": "Apellido",
      "password": "Contraseña",
      "profileImage": "Imagen de perfil",
      "uploadImage": "Arrastra aquí o haz clic para subir una imagen de perfil"
    }
  }]];
});