define("ember-intl/-private/formatters/format-date", ["exports", "ember-intl/-private/formatters/-format-datetime"], function (_exports, _formatDatetime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  /**
   * @private
   * @hide
   */
  class FormatDate extends _formatDatetime.default {}
  _exports.default = FormatDate;
  _defineProperty(FormatDate, "type", 'date');
});