define("ember-simple-auth/utils/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class LocationUtil {
    constructor() {
      _defineProperty(this, "location", window.location);
    }
    replace(...args) {
      this.location.replace(...args);
    }
  }
  const location = new LocationUtil();
  var _default = _exports.default = location;
});